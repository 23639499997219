var render = function () {
  var _vm$dataSoal$file;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('section', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-card', [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Judul",
      "label-for": "Judul"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "Judul"
    },
    model: {
      value: _vm.dataSoal.name,
      callback: function callback($$v) {
        _vm.$set(_vm.dataSoal, "name", $$v);
      },
      expression: "dataSoal.name"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pilih Kategori Paket",
      "label-for": "paket_id"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.optpaket_id,
      "placeholder": "- Pilih -",
      "reduce": function reduce(option) {
        return option.value;
      }
    },
    on: {
      "input": function input($event) {
        return _vm.getDataMapel();
      }
    },
    model: {
      value: _vm.dataSoal.category_paket_id,
      callback: function callback($$v) {
        _vm.$set(_vm.dataSoal, "category_paket_id", $$v);
      },
      expression: "dataSoal.category_paket_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "Pilih Paket"
    }
  }, [_vm._v("Pilih Ujian")]), _c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.optMapel,
      "reduce": function reduce(option) {
        return option.value;
      },
      "placeholder": "- Pilih Kategori paket dahulu -"
    },
    model: {
      value: _vm.$route.query.ujian_id,
      callback: function callback($$v) {
        _vm.$set(_vm.$route.query, "ujian_id", $$v);
      },
      expression: "$route.query.ujian_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Bobot Soal",
      "label-for": "bobot"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "bobot"
    },
    model: {
      value: _vm.dataSoal.bobot,
      callback: function callback($$v) {
        _vm.$set(_vm.dataSoal, "bobot", $$v);
      },
      expression: "dataSoal.bobot"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tipe Soal",
      "label-for": "type"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "label",
      "options": _vm.typesSoal,
      "reduce": function reduce(option) {
        return option.value;
      }
    },
    model: {
      value: _vm.dataSoal.type,
      callback: function callback($$v) {
        _vm.$set(_vm.dataSoal, "type", $$v);
      },
      expression: "dataSoal.type"
    }
  })], 1)], 1), _vm.dataSoal.type == 'benar_salah' ? _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jumlah Opsi Soal",
      "label-for": "opsi"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm.sum_options,
      "reduce": function reduce(option) {
        return option.id;
      }
    },
    model: {
      value: _vm.selectedOption,
      callback: function callback($$v) {
        _vm.selectedOption = $$v;
      },
      expression: "selectedOption"
    }
  })], 1)], 1) : _vm._e(), _vm.dataSoal.type == 'pauli' ? _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jumlah Opsi Soal",
      "label-for": "opsi"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.selectedOption,
      callback: function callback($$v) {
        _vm.selectedOption = $$v;
      },
      expression: "selectedOption"
    }
  })], 1)], 1) : _vm._e(), _vm.dataSoal.type == 'pg' || _vm.dataSoal.type == 'multiple_choice' ? _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jumlah Opsi Jawaban",
      "label-for": "opsi"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm.sum_options,
      "reduce": function reduce(option) {
        return option.id;
      }
    },
    model: {
      value: _vm.selectedOption,
      callback: function callback($$v) {
        _vm.selectedOption = $$v;
      },
      expression: "selectedOption"
    }
  })], 1)], 1) : _vm._e(), _vm.dataSoal.type == 'pg' ? _c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kunci Jawaban",
      "label-for": "opsi"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "label",
      "options": _vm.masterOptions,
      "reduce": function reduce(option) {
        return option.value;
      }
    },
    model: {
      value: _vm.is_correct,
      callback: function callback($$v) {
        _vm.is_correct = $$v;
      },
      expression: "is_correct"
    }
  })], 1)], 1) : _vm._e()], 1)], 1), _vm.dataSoal.type != 'pauli' ? _c('b-tabs', [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Pertanyaan")])];
      },
      proxy: true
    }], null, false, 1644594461)
  }, [_c('b-form', [_c('b-row', [_c('b-col', [_c('quill-editor', {
    attrs: {
      "id": "Konten",
      "options": _vm.editor_options
    },
    model: {
      value: _vm.dataSoal.content,
      callback: function callback($$v) {
        _vm.$set(_vm.dataSoal, "content", $$v);
      },
      expression: "dataSoal.content"
    }
  })], 1)], 1), _c('b-row', [_c('b-col', [_c('label', {
    staticClass: "font-weight-bold mt-2"
  }, [_vm._v("Upload file format: jpg, png, pdf, doc, mp3, mp4 (opsional)")]), _c('b-form-file', {
    attrs: {
      "id": "Thumbnail",
      "placeholder": "Choose a file or drop it here...",
      "drop-placeholder": "Drop file here...",
      "no-drop": "",
      "accept": ".jpg, .png, .pdf, .doc, .mp3, .mp4"
    },
    model: {
      value: _vm.dataSoal.file,
      callback: function callback($$v) {
        _vm.$set(_vm.dataSoal, "file", $$v);
      },
      expression: "dataSoal.file"
    }
  })], 1), _vm.dataSoal.file != null ? _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "md": "12"
    }
  }, [_c('strong', {
    staticClass: "d-block mb-1"
  }, [_c('i', [_vm._v("Lampiran:")])]), _c('file-handle', {
    attrs: {
      "file_path": (_vm$dataSoal$file = _vm.dataSoal.file) === null || _vm$dataSoal$file === void 0 ? void 0 : _vm$dataSoal$file.file,
      "css-props": "max-width: 500px"
    },
    on: {
      "onClick": _vm.onClickAttachment
    }
  })], 1) : _vm._e()], 1)], 1)], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Pembahasan")])];
      },
      proxy: true
    }], null, false, 1343525816)
  }, [_c('b-form', [_c('b-row', [_c('b-col', [_c('quill-editor', {
    attrs: {
      "id": "Pembahasan",
      "options": _vm.editor_options
    },
    model: {
      value: _vm.dataSoal.pembahasan,
      callback: function callback($$v) {
        _vm.$set(_vm.dataSoal, "pembahasan", $$v);
      },
      expression: "dataSoal.pembahasan"
    }
  })], 1)], 1)], 1)], 1)], 1) : _vm._e(), _c('hr'), _vm.dataSoal.type == 'pg' || _vm.dataSoal.type == 'multiple_choice' || _vm.dataSoal.type == 'benar_salah' ? _c('div', [_c('b-tabs', {
    staticClass: "mt-2"
  }, _vm._l(_vm.dataSoal.options, function (data, index) {
    var _data$file;
    return _c('div', {
      key: index
    }, [_c('b-tab', {
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_c('div', [index + 1 == 1 ? _c('label', {
            staticClass: "font-weight-bold"
          }, [_vm._v("Opsi. A")]) : _vm._e(), index + 1 == 2 ? _c('label', {
            staticClass: "font-weight-bold"
          }, [_vm._v("Opsi. B")]) : _vm._e(), index + 1 == 3 ? _c('label', {
            staticClass: "font-weight-bold"
          }, [_vm._v("Opsi. C")]) : _vm._e(), index + 1 == 4 ? _c('label', {
            staticClass: "font-weight-bold"
          }, [_vm._v("Opsi. D")]) : _vm._e(), index + 1 == 5 ? _c('label', {
            staticClass: "font-weight-bold"
          }, [_vm._v("Opsi. E")]) : _vm._e()])];
        },
        proxy: true
      }], null, true)
    }, [_c('b-form', [_c('b-row', [_c('b-col', [_c('quill-editor', {
      attrs: {
        "id": "Konten",
        "options": _vm.editor_options
      },
      model: {
        value: data.content,
        callback: function callback($$v) {
          _vm.$set(data, "content", $$v);
        },
        expression: "data.content"
      }
    }), _vm.dataSoal.type == 'pg' ? _c('b-row', {
      staticClass: "mt-2 mb-1"
    }, [_c('b-col', {
      attrs: {
        "sm": "12",
        "md": "8",
        "lg": "6"
      }
    }, [_c('b-form-group', [_c('label', [_c('span', [_vm._v("Poin Opsi " + _vm._s(_vm.getOption(index)) + ". ( "), _c('i', {
      staticClass: "text-primary"
    }, [_vm._v("hanya isi bagian ini jika ingin membuat soal untuk Tes Kepribadian")]), _vm._v(" )")])]), _c('b-form-input', {
      attrs: {
        "type": "number",
        "placeholder": "Ex: 20"
      },
      model: {
        value: data.point,
        callback: function callback($$v) {
          _vm.$set(data, "point", $$v);
        },
        expression: "data.point"
      }
    })], 1)], 1)], 1) : _vm._e()], 1)], 1), _c('b-row', [_vm.dataSoal.type == 'multiple_choice' || _vm.dataSoal.type == 'benar_salah' ? _c('b-col', {
      attrs: {
        "md": "2"
      }
    }, [index + 1 == 1 ? _c('label', {
      staticClass: "font-weight-bold mt-2"
    }, [_vm._v("Jawaban Opsi. A")]) : _vm._e(), index + 1 == 2 ? _c('label', {
      staticClass: "font-weight-bold mt-2"
    }, [_vm._v("Jawaban Opsi. B")]) : _vm._e(), index + 1 == 3 ? _c('label', {
      staticClass: "font-weight-bold mt-2"
    }, [_vm._v("Jawaban Opsi. C")]) : _vm._e(), index + 1 == 4 ? _c('label', {
      staticClass: "font-weight-bold mt-2"
    }, [_vm._v("Jawaban Opsi. D")]) : _vm._e(), index + 1 == 5 ? _c('label', {
      staticClass: "font-weight-bold mt-2"
    }, [_vm._v("Jawaban Opsi. E")]) : _vm._e(), _vm.dataSoal.type == 'multiple_choice' ? _c('b-form-checkbox', {
      staticClass: "custom-control-primary",
      attrs: {
        "value": "1"
      },
      model: {
        value: data.is_correct,
        callback: function callback($$v) {
          _vm.$set(data, "is_correct", $$v);
        },
        expression: "data.is_correct"
      }
    }, [_vm._v(" Kunci Jawaban ")]) : _vm._e(), _vm.dataSoal.type == 'benar_salah' ? _c('b-form-radio-group', {
      attrs: {
        "options": _vm.bsSoal,
        "name": "radios-stacked",
        "stacked": ""
      },
      model: {
        value: data.is_true_or_false,
        callback: function callback($$v) {
          _vm.$set(data, "is_true_or_false", $$v);
        },
        expression: "data.is_true_or_false"
      }
    }) : _vm._e()], 1) : _vm._e(), _vm.dataSoal.type != 'pg' ? _c('b-col', {
      attrs: {
        "md": "10"
      }
    }, [_c('label', {
      staticClass: "font-weight-bold mt-2"
    }, [_vm._v("Upload file format: jpg, png, pdf, doc, mp3, mp4 (opsional)")]), _c('b-form-file', {
      staticClass: "mb-2",
      attrs: {
        "id": 'file' + data.id,
        "placeholder": "Pilih file untuk di upload atau unggah...",
        "drop-placeholder": "Drop file here...",
        "no-drop": "",
        "accept": ".jpg, .png, .pdf, .doc, .mp3, .mp4"
      },
      model: {
        value: data.opsi_file,
        callback: function callback($$v) {
          _vm.$set(data, "opsi_file", $$v);
        },
        expression: "data.opsi_file"
      }
    })], 1) : _vm._e(), _vm.dataSoal.type == 'pg' ? _c('b-col', {
      attrs: {
        "md": "12"
      }
    }, [_c('label', {
      staticClass: "font-weight-bold mt-2"
    }, [_vm._v("Upload file format: jpg, png, pdf, doc, mp3, mp4 (opsional)")]), _c('b-form-file', {
      staticClass: "mb-2",
      attrs: {
        "id": 'file' + data.id,
        "placeholder": "Pilih file untuk di upload atau unggah...",
        "drop-placeholder": "Drop file here...",
        "no-drop": "",
        "accept": ".jpg, .png, .pdf, .doc, .mp3, .mp4"
      },
      model: {
        value: data.opsi_file,
        callback: function callback($$v) {
          _vm.$set(data, "opsi_file", $$v);
        },
        expression: "data.opsi_file"
      }
    })], 1) : _vm._e(), data.file != null ? _c('b-col', {
      staticClass: "mt-1",
      attrs: {
        "md": "12"
      }
    }, [_c('strong', {
      staticClass: "d-block mb-1"
    }, [_c('i', [_vm._v("Lampiran:")])]), _c('file-handle', {
      attrs: {
        "file_path": (_data$file = data.file) === null || _data$file === void 0 ? void 0 : _data$file.file,
        "css-props": "max-width: 500px"
      },
      on: {
        "onClick": _vm.onClickAttachment
      }
    })], 1) : _vm._e()], 1)], 1)], 1)], 1);
  }), 0)], 1) : _vm._e(), _vm.dataSoal.type == 'pauli' ? _c('div', _vm._l(_vm.item.options, function (data, index) {
    return _c('b-row', {
      key: index
    }, [_c('b-col', {
      attrs: {
        "md": "4"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": 'Baris ' + (index + 1),
        "label-for": 'h-baris-' + (index + 1)
      }
    }, [_c('b-form-input', {
      attrs: {
        "id": 'h-baris-' + data.id,
        "placeholder": "Isi Soal"
      },
      model: {
        value: data.content,
        callback: function callback($$v) {
          _vm.$set(data, "content", $$v);
        },
        expression: "data.content"
      }
    })], 1)], 1), _c('b-col', {
      attrs: {
        "md": "4"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": 'Jawaban Baris ' + (index + 1),
        "label-for": 'h-baris-' + (index + 1)
      }
    }, [_c('b-form-input', {
      attrs: {
        "id": 'h-baris-' + data.id,
        "placeholder": "Isi Jawaban"
      },
      model: {
        value: data.is_correct,
        callback: function callback($$v) {
          _vm.$set(data, "is_correct", $$v);
        },
        expression: "data.is_correct"
      }
    })], 1)], 1)], 1);
  }), 1) : _vm._e(), _c('b-button', {
    staticClass: "w-full mt-3",
    attrs: {
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(" Simpan ")])], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }